<template>
  <div class="nasa">
    <div class="content" style="z-index: 1;" v-if="status === 2">
      <div class="image">
        <img :src="poster"/>
      </div>
      <footer class="mt-3">
        <b-row>
          <b-col>
            <b-btn block @click="status = 0">返回</b-btn>
          </b-col>
          <b-col>
            <b-btn block variant="primary" @click="saveNotify">分享到朋友圈</b-btn>
          </b-col>
        </b-row>
      </footer>
    </div>

    <div class="content" v-else>
      <div class="desc">
        美国宇航局NASA为纪念哈勃望远镜服役30年，为每个人生日当天挑选了一张宇宙照片，来看看你的宇宙生日照吧
      </div>
      <form @submit.prevent="onSubmit">
        <h1 v-image-text>请选择你的生日</h1>
        <b-row>
          <b-col>
            <select class="month" v-model="month" @change="onMonthChange">
              <option value="">请选择月份</option>
              <option v-for="i in 12" :key="'m' + i" :value="i">
                {{i}}月
              </option>
            </select>
          </b-col>
          <b-col>
            <select class="date" v-model="date" :disabled="!month">
              <option value="">请选择日期</option>
              <option v-for="i in daysInMonth" :key="'m' + i" :value="i">{{i}}</option>
            </select>
          </b-col>
        </b-row>

        <footer class="mt-5">
          <button class="submit" :disabled="!month || !date || status === 1" type="submit">
            <fa icon="spinner" spin v-if="status === 1"/>
            {{status === 1 ? '正在提交' : '提交'}}
          </button>
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'

export default {
  name: 'nasa',
  title: '我的宇宙生日照，纪念「哈勃」服役30年',
  data() {
    return {
      db: [],
      month: '',
      date: '',
      status: 0
    }
  },
  async created() {
    this.db = await this.$req.get('/buckets/weixinshu:nasa')
    configShare({
      title: '我的宇宙生日照，纪念「哈勃」服役30年',
      desc: '美国宇航局NASA为纪念哈勃望远镜服役30年，为每个人生日当天挑选了一张宇宙照片，来看看你的宇宙生日照吧',
      imgUrl: 'https://img.xinshu.me/upload/6bd316197f8347eaabf80fb8a334046d',
      link: 'https://weixinshu.com/promotions/nasa'
    })
  },
  computed: {
    daysInMonth() {
      return this.$day('2012-' + (this.month || 1) + '-01', 'YYYY-MM').daysInMonth()
    },
    poster() {
      const date = ('0' + this.month).slice(-2) + ('0' + this.date).slice(-2)
      const day = this.db.find(i => i.date.toString() === date.toString())
      if (!day) {
        return ''
      }
      const query = {
        nickname: this.user.nickname,
        avatar: this.user.avatar,
        month: parseInt(day.date.slice(0, 2)),
        day: parseInt(day.date.slice(2)),
        date: this.$day(day.year + day.date).format('YYYY.MM.DD'),
        image: day.image,
        caption: day.caption,
        galaxy: day.name
      }
      return 'https://canvas.xinshu.me/generate/nasa' + this.serialize(query)
    }
  },
  methods: {
    onMonthChange() {
      if (this.date) {
        this.date = Math.min(this.daysInMonth, this.date)
      }
    },
    saveNotify() {
      alert('请长按保存图片到相册后分享')
    },
    async onSubmit() {
      try {
        this.status = 1
        await this.$req.get(this.poster)
        this.status = 2
      } catch (err) {
        this.status = 0
      }
    }
  }
}
</script>

<style>
  [data-page="nasa"] main {
    padding-bottom: 0 !important;
  }
</style>

<style lang="scss" scoped>
  .nasa {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    background-image: url(https://img.xinshu.me/upload/73d5b7926e5c49f49f362fa9fd8f8dbe!1920);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0;

    .desc {
      font-size: 12px;
      position: absolute;
      padding: 1rem;
      top: 0;
      left: 0;
      right: 0;
      opacity: .5;
    }

    h1 {
      font-size: 4em;
      margin-bottom: 2rem;
    }

    input:focus + .value-list {
      height: 15em;
    }

    .value-list {
      list-style: none;
      height: 0;
      overflow: auto;
      padding: 0;
      margin: 0;
      background-color: #fff;
      margin-top: .5em;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      transition: .5s;
      position: absolute;
      top: 4rem;
      left: 10px;
      right: 10px;
      color: #000;
      font-size: 1.25em;
      z-index: 5;

      li {
        height: 3em;
        line-height: 3em;
        cursor: pointer;

        &.active {
          background-color: $primary;
          color: #fff;
        }
      }
    }

    .month, .date {
      display: block;
      width: 100%;
      padding: 1rem;
      font-size: 1.5em;
      text-align: center;
      border-radius: 0;
      border: 0;
      line-height: 1;
      -webkit-appearance: none;
      background-color: #fff;
      cursor: pointer;

      &::placeholder {
        transition: .3s;
        color: #333;
      }

      &:focus {
        background-color: opacify($primary, 0.5);
        color: #fff;

        &::placeholder {
          color: #fff;
        }
      }
    }

    .image {
      display: block;
      flex: 1;
      height: calc(#{100vh} - 80px);
      img {
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        max-height: 100%;
        max-width: 100%;
      }
    }

    .submit {
      width: 10em;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 320px;
      border: 1px solid $primary;
      background-color: $primary;
      color: #fff;
      padding: 1rem;
      font-size: 1.5em;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);

      &:disabled {
        opacity: .85;
      }
    }

    .content {
      position: relative;
      z-index: 1;
      max-width: 640px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: #fff;
      padding: 1rem;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom right, #002f4b, #dc4225);
      opacity: .75;
      z-index: 0;
      pointer-events: none;
    }
  }
</style>
